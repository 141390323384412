.card {
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 5em;
    margin: 5em;
}

@media screen and (max-width: 1300px) {
    .card {
        padding: 2em;
        margin: 0em;
    }
}