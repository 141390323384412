.custom-input {
    margin-bottom: 15px;
  }
  
  .custom-input label {
    display: block;
    margin-bottom: 5px;
    color: #050000; 
    font-size: 14px;
  }
  
  .custom-input input {
    padding: 10px;
    border: 1px solid #BBB; 
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease; 
    width: calc(100% - 20px);
  }
  
  .custom-input input:focus {
    border-color: #007bff; 
  }
  
  .custom-input.focused input {
    border-color: #007bff; 
  }
  