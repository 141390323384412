.section--top {
    background: url('../../assets/images/top-bg.svg') center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    min-height: 600px;
    background-position: bottom center;
}

a {
    text-decoration: none;
}

h1 {
    color: white;
}

h1 span {
    filter: drop-shadow(0 2px 8px rgb(255 255 255 / 0.2)) drop-shadow(0 2px 8px rgb(255 255 255 / 0.2))
}

h3 {
    color: white;
    font-weight: 500;
}

.button-container{
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 30px;
}