.section--top {
    background: url('../../assets/images/top-bg.svg') center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    min-height: 600px;
    background-position: bottom center;
}