.settings-container {
    display: flex;
}

.settings-content {
    display: flex;
    flex-direction: column; 
    height: 100vh;
    width: calc(100% - 260px);
    margin-left: 250px;
    margin-right: 250px;
    margin-top: 50px;
    gap: 20px; 
    
    input {
        max-width: 100px;
    }

    button {
        max-width: 200px;
    }
}

.hours-container {
    display: flex;

    gap: 20px;
  }