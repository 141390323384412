.sidebar {
    width: 200px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #4266f5;
    overflow-x: hidden;
    padding-top: 20px;
    box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.2);
}

.sidebar h1 {
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    padding: 15px;
    text-align: center;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: transparent;
    border-radius: 10px; 
    font-size: 1.1em; 
    margin: 10px
}

.sidebar ul li:hover {
    color: #10155E;
    background-color: #f0f0f0;
}

.sidebar a {
    text-decoration: none;
    color: inherit;
}

.sidebar a:hover {
    text-decoration: none;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
        box-shadow: none;
    }
}
