.content {
    display: flex;
    height: 100dvh;
}

.graphic {
    background: url('../../assets/images/background.png');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 50%;
}