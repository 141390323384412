.button {
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    height: 50px;
    text-transform: none;
    min-width: 100px;
    width: 100%;
}

.button--addtask {
    background: linear-gradient(104.93deg,#eb5757 -7.87%,#d94e8c 53.37%);
}

.button--export {
    background: linear-gradient(270deg,#78a0db 0%,#6298e6 56.56%,#5592ed 90.98%);
}
