.dashboard-container {
    display: flex;
}

.dashboard-content {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    margin-left: 200px; 
    width: calc(100% - 200px);
    height: 100vh;
}


.dashboard-content h1 {
    margin-bottom: 30px;
    color: black;
    text-align: center;
    font-weight: 500;
}

.dashboard-content h3 {
    color: black;
    font-weight: 500;
    text-align: center;
}

.changelog {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-bottom: 20px; 
}
