.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f2f2f2;
    color: #333;
    }
    
    .not-found-container h1 {
    font-size: 5rem;
    margin: 0;
    color: black;
    }
    
    .not-found-container p {
    font-size: 1.5rem;
    color: black;
    }
    
    