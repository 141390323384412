.sub-content {
    display: flex;
    flex-direction: column; 
    height: 100vh;
    width: calc(100% - 260px);
    margin-left: 250px;
    margin-right: 250px;
    margin-top: 50px;
    gap: 20px; 
  }
  input {
    max-width: 300px;
  }
  button {
    max-width: 550px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .tasks-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  
  .results-container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  